<template>
  <div class="training-zl">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>培训资料列表</span>
        <el-button
          style="float: right; padding: 5px 5px"
          type="success"
          @click="addNews"
          >添加资料</el-button
        >
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        stripe
        style="width: 100%"
      >
        <el-table-column prop="id" label="索引" align="center">
        </el-table-column>
        <el-table-column prop="title" label="标题名称" align="center">
        </el-table-column>
        <el-table-column prop="text" label="内容" align="center" style="height:80px">
           <template slot-scope="scope"><span v-html="scope.row.text"></span></template>
        </el-table-column>
        <el-table-column prop="name" label="所属分类" align="center">
        </el-table-column>
        <el-table-column prop="add" label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="exitVisible(scope.$index, scope.row)">编辑</el-button>
            <el-button
              type="text"
              class="delete"
              @click="delList(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <div class="pagination">
        <el-pagination
    layout="prev, pager, next"
    :page-size='pages.pageSize'
    :current-page="pages.startPage"
    @next-click="nextClick"
    @prev-click='prevClick'
    @current-change='currentChange'
    prev-text='上一页'
    next-text='下一页'
    :total="pages.total">
  </el-pagination>
    </div>
  </div>
</template>

<script>
import {materialCreate,materialSave,materialEdit,materialUpdate,materialList,materialDelete} from '@/api/zltype.js'
export default {
  components:{
  },
  data () {
    return {
      tableData: [],
      pages:{
        startPage:1, // 当前页
        total:null,  //总条数
        pageSize:10  // 一页几条信息
      }
    }
  },
  created () {
    this.addList(this.pages.startPage)
  },
  methods: {
    // 分页 下一页
    nextClick(size) {
      this.pages.startPage = size
      this.addList(size)
    },
    prevClick(size) {
      this.pages.startPage = size
      this.addList(size)
    },
    currentChange(size) {
      this.pages.startPage = size
      this.addList(size)
    },
    addNews () {
      this.$router.push({path:'../training/training-addnews',query:{type:0}})
    },
    // 获取列表
    async addList (pages) {
        const res = await materialList({ page: pages })
        res.data.result.list.forEach(item=>{
          item.text = this.translateRichText(item.content)
        })
        this.tableData = res.data.result.list
        this.pages.total = res.data.result.count
    },
    // 删
    async delList (index ,row) {
      const res = await materialDelete ({id:row.id})
      if(res.data.code === 1){
        this.$message.success('删除成功')
        setTimeout(() => {
          this.addList(this.pages.startPage)
        }, 500);
      }
    },
    // 改
    async exitVisible (index ,row) {
      const res = await materialEdit({id:row.id})
      if(res.data.code == 1) {
        this.$router.push({path:'./training-addnews',query:{
          cate:res.data.result.cate,
          info:res.data.result.info,
          type:1,
        }})
      }
    },
    // 转译富文本
    translateRichText (content) {
      let arrEntities = { lt: '<', gt: '>', nbsp: ' ', amp: '&', quot: '"' }
      return content.replace(/&(lt|gt|nbsp|amp|quot);/gi, function (all, t) {
        return arrEntities[t]
      })
    }
  }
}
</script>

<style lang="less" scoped>
.training-zl {
  padding: 20px 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  .box-card {
    .coverImg {
      width: 170px;
      height: 70px;
      border: 1px solid #ccc;
    }
  }
  .pagination{
    margin-top: 20px;
    text-align: right;
  }
  .delete {
    color: #f6423b;
  }
  ::v-deep .el-table .cell{
    height: 60px !important;
    line-height: 60px;
  }
}
</style>
